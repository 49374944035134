/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

const acelynkOptions = [
  { label: '' },
  { label: 'Skip' },
  { label: 'Invoice Number' },
  // { label: 'Invoice Date'},
  { label: 'Importer Reference Number' },
  { label: 'SCAC' },
  { label: 'Bill of Lading' },
  { label: 'Quantity' },
  { label: 'UOM (Unit of Measurement)' },
  { label: 'IT Number' },
  { label: 'IT Date' },
  { label: 'Consignee' },
  { label: 'Consignee Tax ID' },
  // { label: 'Charges' },
  // { label: 'Street', heading: 'Consignee' },
  // { label: 'Line 2', heading: 'Consignee' },
  // { label: 'City', heading: 'Consignee' },
  // { label: 'State/Province', heading: 'Consignee' },
  // { label: 'Country', heading: 'Consignee' },
  // { label: 'ID', heading: 'Consignee' },
  { label: 'Manufacturer/Supplier' },
  { label: 'Manufacturer/Supplier Tax ID' },
  // { label: 'Street', heading: 'Manufacturer/Supplier' },
  // { label: 'Line 2', heading: 'Manufacturer/Supplier' },
  // { label: 'City', heading: 'Manufacturer/Supplier' },
  // { label: 'State/Province', heading: 'Manufacturer/Supplier' },
  // { label: 'Country', heading: 'Manufacturer/Supplier' },
  // { label: 'ID', heading: 'Manufacturer/Supplier' },
  { label: 'Seller' },
  { label: 'Seller Tax ID' },
  // { label: 'Street', heading: 'Seller' },
  // { label: 'Line 2', heading: 'Seller' },
  // { label: 'City', heading: 'Seller' },
  // { label: 'State/Province', heading: 'Seller' },
  // { label: 'Country', heading: 'Seller' },
  // { label: 'ID', heading: 'Seller' },
  { label: 'Buyer' },
  { label: 'Buyer Tax ID' },
  // { label: 'Street', heading: 'Buyer' },
  // { label: 'Line 2', heading: 'Buyer' },
  // { label: 'City', heading: 'Buyer' },
  // { label: 'State/Province', heading: 'Buyer' },
  // { label: 'Country', heading: 'Buyer' },
  // { label: 'ID', heading: 'Buyer' },
  // { label: 'Curreny', heading: 'Buyer' },
  { label: 'FIRMS Code' },
  { label: 'Release Port' },
  { label: 'Port of Unlading' },
  // { label: 'Entry Election Code' },
  // { label: 'Entry Election Date'},
  { label: 'Arrival Date' },
  // { label: 'Import Date' },
  { label: 'Country of Origin' },
  { label: 'Country of Export' },
  // { label: 'Manifest Description' },
  { label: 'Export Date' },
  { label: 'Gross Weight' },
  { label: 'Invoice Date' },
  { label: 'Currency' },
  { label: 'Ship To' },
  { label: 'Ship To Tax ID' },
];
const acelynkLineItemOptions = [
  { label: '' },
  { label: 'Skip' },
  { label: 'Invoice Total' },
  { label: 'Part #' },
  { label: 'HTS #' },
  { label: 'Commercial Description' },
  // { label: 'Charges'},
  { label: 'Quantity' },
  { label: 'Unit Price' },
  { label: 'Line Value' },
  { label: 'Manufacturer' },
  { label: 'Country of Origin' },
  { label: 'Gross Weight' },
];

const DropDown = ({
  selectOption,
  changeOption,
  value,
}) => {
  let options = []
  if (selectOption == 'table') {
    options = acelynkLineItemOptions
  } else {
    options = acelynkOptions
  }
  return (
    <Autocomplete
      onChange={changeOption}
      disableClearable={true}
      options={options}
      defaultValue={value}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.label === value.label}
      style={{ width: '100%' }}
      renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" />}
    />
  )
}
DropDown.propTypes = {
  selectOption: PropTypes.string || null,
  changeOption: PropTypes.func || null,
  value: PropTypes.object || null,
};
export default DropDown
