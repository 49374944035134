import React, {useState, useEffect} from 'react';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { green, red, orange } from '@material-ui/core/colors';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import Dropdown from "components/Dropdown/Dropdown.js";

// #region constants

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  MatchedCell: {
    backgroundColor: "rgba(0,0,0,0.12)",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const formStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.gray,
    color: theme.palette.common.black,
    borderRight: '2px solid #0000006e',
  },
  body: {
    fontSize: 14,
    borderRight: '2px solid #0000006e',
    height: '60px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

/**
 * This function creates an array of elements from start to stop with the step provided as parameter
 * The nextStep function is executed for each item and calculates the next element of the array
 * @param {number} start Start element
 * @param {number} stop Stop element
 * @param {number} step Step used to create the array of elements
 * @return {Array} Array of items.
 */
const createArray = (start, stop, step) => {
  const nextStep = (_, i) => start + (i * step);
  return Array.from({ length: (stop - start) / step + 1}, nextStep);
}

// #region component

/**
 * Mapped page table
 */
const TablePage = ({table, onDropdownChange, currentTable, currentPage, pageCount, tableCount, mappedDocument, onMap}) => {

  if (!table)
  {
    return <div> <b> No tables found </b> </div>;
  }

  // Styles
  const classes = useStyles();
  const formClasses = formStyles();

  // Skip table option
  // const [open, setOpen] = React.useState(false);
  const [skipTable, setSkipTable] = useState(table.skip ?? false);
  const [tableHeaderAsLineItem, setTableHeaderAsLineItem] = useState(table.useHeaderAsLineItem ?? false);
  const [pageSelected, setPageSelected] = useState('');
  const [tableSelected, setTableSelected] = useState('');
  const pageArray = createArray(1, pageCount, 1);
  const tableArray = createArray(1, tableCount, 1);

  const updateHeaders = (table, acelynkField) => {
    // We need to update headers from table and mappedDocument in order to persist these changes
    // if the user saves the mapping.
    table.header.forEach(element => {
      element.acelynkField = acelynkField;
    });
    table.skip = table.header.findIndex(column => column.acelynkField !== 'Skip') < 0;

    var rows = mappedDocument.rows.filter(row =>
      row.pageIndex === (currentPage - 1) &&
      row.tableIndex === (currentTable - 1));
    rows.forEach(row => {
      row.acelynkField = acelynkField;
    });
  };

  // TODO: Refactor this to save all additional user configuration of mapped rows using a different data structure.
  const updateTableHeaderAsLineItems = (useHeaderAsLineItem) => {
    // We need to update all line items from mappedDocument in order to persist these changes
    // if the user saves the mapping.
    setTableHeaderAsLineItem(useHeaderAsLineItem);

    var rows = mappedDocument.rows.filter(row =>
      row.pageIndex === (currentPage - 1) &&
      row.tableIndex === (currentTable - 1));
    rows.forEach(row => {
      row.useHeaderAsLineItem = useHeaderAsLineItem;
    });
  };

  const onSkipTableChanged = () => {
    if (skipTable) {
      setSkipTable(false);
      updateHeaders(table, '');
    } else {
      setSkipTable(true);
      updateHeaders(table, 'Skip');
    }
  };

  const onTableHeaderAsLineItemSelected = () => {
    table.useHeaderAsLineItem = !table.useHeaderAsLineItem;
    updateTableHeaderAsLineItems(table.useHeaderAsLineItem);
  };

  const onPageSelectedChange = (event) => {
    setPageSelected(event.target.value);
  }

  const onTableSelectedChange = (event) => {
    setTableSelected(event.target.value);
  }

  useEffect(() => {
    setSkipTable(table.skip);
    // If the useHeaderAsLineItem flag is not defined, we want to check it on all the pages but the first page
    if (table.useHeaderAsLineItem === undefined) {
      table.useHeaderAsLineItem = currentPage === 1 ? false : true;
    }
    setTableHeaderAsLineItem(table.useHeaderAsLineItem);
  }, [table]);

  return (
    <div >
    <FormGroup style={{ flexDirection: 'row', padding: '5px 10px' }}>
      <FormControlLabel name="skipTable" label="Skip Table" labelPlacement="start"
        control={<Switch checked={skipTable} onChange={() => onSkipTableChanged()} />} />
      <FormControlLabel style={{ marginLeft: '12px', paddingLeft: '5px', borderLeft: '2px solid rgba(0, 0, 0, 0.43)' }} name="headerAsLineItem"
        label="Use table header as line item" labelPlacement="start"
        control={<Switch checked={tableHeaderAsLineItem} onChange={() => onTableHeaderAsLineItemSelected()} />} />
      <FormControlLabel style={{ marginLeft: '12px', paddingLeft: '5px', borderLeft: '2px solid rgba(0, 0, 0, 0.43)' }} name="tableMapping"
        control={<Typography >Apply current table mapping to other table:</Typography>}  />
      <FormControl variant="outlined" className={formClasses.formControl}>
        <InputLabel id="page-select-outlined-label">Page</InputLabel>
        <Select
          style={{ paddingLeft: '12px' }}
          labelId="page-select-label"
          id="page-select"
          value={pageSelected}
          onChange={onPageSelectedChange}
        >
          <MenuItem key={(((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)} value="">
            <em>None</em>
          </MenuItem>
          <MenuItem key={(((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)} value={0}>
            <em>All</em>
          </MenuItem>
          {pageArray.map(page => (
            <MenuItem key={(((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)} value={page}>{page}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={formClasses.formControl}>
        <InputLabel id="table-select-outlined-label">Table</InputLabel>
        <Select
          style={{ paddingLeft: '12px' }}
          labelId="page-select-label"
          id="page-select"
          value={tableSelected}
          onChange={onTableSelectedChange}
        >
          <MenuItem key={(((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)} value="">
            <em>None</em>
          </MenuItem>
          <MenuItem key={(((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)} value={0}>
            <em>All</em>
          </MenuItem>
          {tableArray.map(page => (
            <MenuItem key={(((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)} value={page}>{page}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="outlined" onClick={() => onMap(currentPage, currentTable, pageSelected, tableSelected)} color="primary">
        Map
      </Button>
    </FormGroup>
    <Table className={classes.table} aria-label="table"
    >
      <TableHead>
        <TableRow key={(((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)}>
          {table.header?.map((header, hIdx) => {
            let matched = '';
            if (header.acelynkField == 'Skip') {
              matched = 'skiped';
            } else if (header.acelynkField) {
              matched = 'matched';
            } else {
              matched = 'notmatched';
            }
            return (
              <StyledTableCell style={{ width: '200px' }} className={classes.MatchedCell} align="center" key={(((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)}>
                <Dropdown id={header.tableHeader} value={{ label: header.acelynkField }} selectOption='table' changeOption={(_, val) => onDropdownChange(val.label, hIdx, header.tableHeader.trim().replace(/\n/g, ''), true)} ></Dropdown>
                {matched == 'matched' &&
                  <CheckCircleIcon style={{ color: green[500], marginBottom: '10px', marginTop: '10px', fontSize: '30px' }} />
                }
                {matched == 'notmatched' &&
                  <CancelIcon style={{ color: red[500], marginBottom: '10px', marginTop: '10px', fontSize: '30px' }} />
                }
                {matched == 'skiped' &&
                  <BlockIcon style={{ color: orange[500], marginBottom: '10px', marginTop: '10px', fontSize: '30px' }} />
                }
                <hr></hr>
                <Typography style={{ width: '200px' }} noWrap className={classes.heading}>
                  <strong>{header.tableHeader}</strong>
                </Typography>
              </StyledTableCell>
            )
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {table.body?.map((bodyMain) => (
          <StyledTableRow key={(((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)}>
            {bodyMain.map((body) => (
              <StyledTableCell key={(((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)} align="center">
                {body}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
    </div>);
}
// #endregion

export default TablePage;