import React, { useMemo } from 'react';
import classNames from 'classnames';
import './pagination.scss';

//hooks
import { usePagination } from 'hooks';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component

/**
 * Component used to add pagination for a list of items
 */
const Pagination = (props) => {
  const {
    pageSize,
    pageCount,
    onPageChange,
    currentPage,
    siblingCount = 1
  } = props;

  const DOTS = '...';

  const paginationRange = usePagination({
    currentPage,
    pageCount,
    siblingCount,
    pageSize,
    DOTS
  });

  if (currentPage == 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const className="pagination-bar";

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className={classNames('pagination-center')}>
      <ul className={classNames('pagination-container')}>
        {/* Left navigation arrow */}
        <li
          className={classNames('pagination-item', {
            disabled: currentPage === 1
          })}
          onClick={onPrevious}
          key={(((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)}
        >
          <div className="arrow left" />
        </li>
        {paginationRange.map(page => {
          // If the pageItem is a DOT, render the DOTS unicode character
          if (page === DOTS) {
            return <li key={(((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)} className="pagination-item dots">&#8230;</li>;
          }
          // Render our Page Pills
          return (
            <li
              className={classNames('pagination-item', {
                selected: page === currentPage
              })}
              onClick={() => onPageChange(page)}
              key={(((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)}
            >
              {page}
            </li>
          );
        })}
        {/* Right navigation arrow */}
        <li
          className={classNames('pagination-item', {
            disabled: currentPage === lastPage
          })}
          onClick={onNext}
          key={(((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)}
        >
          <div className="arrow right" />
        </li>
        <div className={classNames('pagination-text')}>Page <b>{currentPage}</b> of <b>{Math.ceil(pageCount / pageSize)}</b></div>
      </ul>
    </div>
  );

};
// #endregion

export default Pagination;