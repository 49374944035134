import React, {useState, useEffect} from 'react';

// #region constants

// #endregion

// #region styled-components
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { withStyles } from "@material-ui/core/styles";
// #endregion

// #region functions

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

// #endregion

// #region component

const StyledTab = withStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    textTransform: "capitalize",
    color: "#000",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      color: "rgba(0, 0, 0, 1.0)",
    }
  },
  textColorPrimary: {
    color: "rgba(0, 0, 0, 0.54)",

  },
  textColorSecondary: {
    color: "#fff",

  },
  selected: {
    color: "#000",
    backgroundColor: "rgb(44 35 35 / 12%)",
  }
}))(Tab);

const StyledTabs = withStyles((theme) => ({
  indicator: {
    backgroundColor: "#fb8c00"
  }
}))(Tabs);

/**
 * Component used to add pagination for a list of tables
 */
const TableTabs = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
  const pageNumbers = [];
  const [pageIndex, setPageIndex] = useState(currentPage - 1);

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleChangeTable = (_, newValue) => {
    setPageIndex(newValue)
    paginate(newValue + 1);
  };

  useEffect(() => {
    setPageIndex(currentPage - 1);
  }, [currentPage]);

  return (
    <AppBar position="static" color="default">
    <StyledTabs
        value={pageIndex}
        onChange={handleChangeTable}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="table tabs"
      >
        {pageNumbers.map(pageNumber => (
          <StyledTab key={pageNumber} label={`Table ${pageNumber}`} {...a11yProps(pageNumber)} />
        ))}
      </StyledTabs>
      </AppBar>
  );
};
// #endregion

export default TableTabs;