import React, {useState, useEffect} from 'react';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from '@material-ui/core/Checkbox';

import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { green, red, orange } from '@material-ui/core/colors';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";

import Dropdown from "components/Dropdown/Dropdown.js";
import TablePage from "./TablePage";
import TableTabs from "./TableTabs";


// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

const useStylesTable = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.gray,
    color: theme.palette.common.black,
    borderRight: '2px solid #0000006e',
  },
  body: {
    fontSize: 14,
    borderRight: '2px solid #0000006e',
    height: '60px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  MatchedCell: {
    backgroundColor: "rgba(0,0,0,0.12)",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

// #endregion

// #region component

/**
 *  Mapped document page
 */
const MappedPage = ({ pageItem, onAcelynkKeyChanged, onSelectedValueChanged, pageCount, currentPage, mappedDocument, onMap }) => {
  const [tabIndex, setTabIndex] = useState(0);

  // Table Pagination
  const [currentTable, setCurrentTable] = useState(1);
  const [itemsPerPage] = useState(1);

  // Styles
  const theme = useTheme();
  const classes = useStyles();
  const classes3 = useStylesTable();


  const handleChangeTable = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleChangeIndex = (index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    setCurrentTable(1);
  }, [pageItem]);

  return (<div className={classes3.root}>
    <AppBar position="static" color="default">
      <Tabs
        value={tabIndex}
        onChange={handleChangeTable}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="Tab selector"
      >
        <Tab label="Key Value Pairs" {...a11yProps(0)} />
        <Tab label="Tables" {...a11yProps(1)} />
      </Tabs>
    </AppBar>
    <SwipeableViews
      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
      index={tabIndex}
      onChangeIndex={handleChangeIndex}
    >
      <span value={tabIndex} index={0} dir={theme.direction}>
        <TableContainer style={{ width: "100%", height: '950px' }}>
          <Table
            className={classes.table}
            aria-label="keypairs"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">
                  Matched
                </StyledTableCell>
                <StyledTableCell style={{ width: '200px' }} align="center">
                  Acelynk Keys
                </StyledTableCell>
                <StyledTableCell style={{ width: '200px' }} align="center">
                  Use Parsed Key as Value
                </StyledTableCell>
                <StyledTableCell align="center">
                  Parsed Keys
                </StyledTableCell>
                <StyledTableCell align="center">
                  Parsed Key Value
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {pageItem.keyPairs.map((key) => {
                let matched = '';
                if (key.acelynkField == 'Skip') {
                  matched = 'skiped';
                } else if (key.acelynkField) {
                  matched = 'matched';
                } else {
                  matched = 'notmatched';
                }
                return (<StyledTableRow key={(((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)}>
                  <StyledTableCell align="center" component="th" scope="row">
                    {matched == 'matched' &&
                      <CheckCircleIcon style={{ color: green[500], fontSize: '30px' }} />
                    }
                    {matched == 'notmatched' &&
                      <CancelIcon style={{ color: red[500], fontSize: '30px' }} />
                    }
                    {matched == 'skiped' &&
                      <BlockIcon style={{ color: orange[500], fontSize: '30px' }} />
                    }
                  </StyledTableCell>
                  <StyledTableCell align="center" component="th" scope="row">
                    <Dropdown id={key.labelCordinates[0].x.toString()} key={key.fieldName} value={{ label: key.acelynkField }} changeOption={(_, val) => onAcelynkKeyChanged(val.label, key.fieldValue, key.fieldName.trim().replace(/\n/g, ''), false, null)} selectOption='n'></Dropdown>
                  </StyledTableCell>
                  <StyledTableCell align="center" component="th" scope="row">
                    <Checkbox id={key.labelCordinates[0].x.toString()} checked={key.useKeyAsValue} onChange={(evt) => onSelectedValueChanged(evt.target.checked, key.fieldValue, key.fieldName.trim().replace(/\n/g, ''))} inputProps={{ 'aria-label': 'Use key as value' }} />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {key.fieldName}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {key.fieldValue}
                  </StyledTableCell>
                </StyledTableRow>)
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </span>
      <span value={tabIndex} index={1} dir={theme.direction}>
        <TableContainer style={{ width: "100%", height: '950px' }}>
          { pageItem.tables.length > 0 &&
            (<div> <br/><TableTabs itemsPerPage={itemsPerPage} totalItems={pageItem.tables.length} paginate={(pageNumber)=>setCurrentTable(pageNumber)} currentPage={currentTable}/> </div>)}
          <br />
          <TablePage table={pageItem.tables[currentTable-1]}
                     currentTable={currentTable}
                     currentPage={currentPage}
                     pageCount={pageCount}
                     tableCount={pageItem.tables.length}
                     mappedDocument={mappedDocument}
                     onDropdownChange={(option, value, key, header) => onAcelynkKeyChanged(option, value, key, header, currentTable-1)}
                     onMap={(currentPage, currentTable, pageSelected, tableSelected) => onMap(currentPage, currentTable, pageSelected, tableSelected)}/>
        </TableContainer>
      </span>
    </SwipeableViews>
  </div>);
}
// #endregion

export default MappedPage;