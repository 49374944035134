/**
 * MappedRow class
 * Helps to map all the fields needed from each document row to save that mapping
 * configuration in the DB
 */
export class MappedRow {
  /**
   * Mapped Row constructor
   * @param {string} filerCode Acelynk filer code from the user
   * @param {string} importerAccount Acelynk importer account from the user
   * @param {string} documentId Unique Document Id
   * @param {string} acelynkField Acelynk form field
   * @param {string} fieldLabel Google processor's field label
   * @param {string} userId Acelynk's user Id
   * @param {string} modifiedBy Acelynk's user Id
   * @param {string} mappingCompleted Indicate if the field mapping is completed
   * @param {string} confidence Field's confidence rate from Google parser
   * @param {string} fieldValue Google processor's field value
   * @param {string} pageIndex Field page index
   * @param {string} tableIndex Field table index
   */
  constructor(
    filerCode,
    importerAccount,
    documentId,
    acelynkField,
    fieldLabel,
    userId,
    modifiedBy,
    mappingCompleted,
    confidence,
    fieldValue,
    pageIndex,
    tableIndex,
  ) {
    this.filerCode = filerCode;
    this.importerAccount = importerAccount;
    this.documentId = documentId;
    this.acelynkField = acelynkField;
    this.fieldLabel = fieldLabel;
    this.userId = userId;
    this.modifiedBy = modifiedBy;
    this.mappingCompleted = mappingCompleted;
    this.confidence = confidence;
    this.fieldValue = fieldValue;
    this.pageIndex = pageIndex;
    this.tableIndex = tableIndex;
  }
}

/**
 * MappedDocument class
 * Helps to map all the rows from the document in the DB
 */
export class MappedDocument {
  /**
   * MappedDocument constructor
   * @param {Array} mappedRows Array of mapped rows from the document
   */
  constructor(
    mappedRows,
  ) {
    this.rows = mappedRows || Array.from([]);
  }

  getMappedRows() {
    const mappedRows = Array.from([]);
    this.rows.map((row) => {
      if (row.acelynkField) {
        mappedRows.push(row);
      }
    });
    return mappedRows;
  }
}