import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import { DropzoneArea } from "material-ui-dropzone";
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import axios from 'axios';
// import qs from 'qs'
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import FileViewer from 'react-file-viewer';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import queryString from 'query-string';

import DocumentViewer from './DocumentViewer';
import MappedPage from './MappedPage';
import Pagination from './Pagination';
import { MappedDocument } from 'models/mapped-document';

function Error(message) {
  return <h3>{message}</h3>;
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  MatchedCell: {
    backgroundColor: "rgba(0,0,0,0.12)",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const dropStyles = makeStyles((theme) => ({ backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff', }, }));

const useStyles = makeStyles(styles);

export default function Documents() {
  const [showImage, setShowImage] = useState(false);
  const [uploadedFile, setUploadedFile] = useState('');
  const [loading, setLoading] = React.useState(false);
  const [pagesArray, setPagesArray] = React.useState([]);
  const [mappedDocument, setMappedDocument] = React.useState(new MappedDocument());

  const [url, setUrl] = React.useState('');
  const [openMessage, setOpenMessage] = React.useState(false);
  const [openEntryMessage, setOpenEntryMessage] = React.useState(false);
  const [entrySummaryError, setEntrySummaryError] = React.useState(false);
  const [entrySummaryErrorMessage, setEntrySummaryErrorMessage] = React.useState('');
  const [mappingApplied, setMappingApplied] = React.useState(false);
  const [mappingError, setMappingError] = React.useState(false);
  const [mappingErrorMessage, setMappingErrorMessage] = React.useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(1);
  const [currentItem, setCurrentItem] = useState({});

  /* Check for required query parameters to process an invoice.
   * If filercode or importeraccount is not defined, return an error.
   */
  const { search } = useLocation();
  const { filercode, importeraccount } = queryString.parse(search);

  if (!filercode) {
    return Error(`'filercode' is not defined`);
  }

  if (!importeraccount) {
    return Error(`'importeraccount' is not defined`);
  }

  const setPage = (page) => {
    // Get current page
    const indexOfLastItem = page * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const newCurrentItem = pagesArray?.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItem(newCurrentItem[0]);
    setCurrentPage(page);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
    setOpenEntryMessage(false);
    setEntrySummaryError(false);
    setEntrySummaryErrorMessage('');
    setMappingApplied(false);
    setMappingError(false);
    setMappingErrorMessage('');
  };

  const handleChange = () => {

    setLoading(true);
    const formData = new FormData();
    formData.append('file', uploadedFile); // appending file
    formData.append('filercode', filercode);
    formData.append('importeraccount', importeraccount);

    axios.post(`${process.env.REACT_APP_DOCAI_BACKEND_URL}/document/parse`, formData, {
    }).then(response => {
      setMappedDocument(response.data.mappedDocument);
      setPagesArray(response.data.mappedDocument.pages);

      // Get current page
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItem = response.data.mappedDocument.pages?.slice(indexOfFirstItem, indexOfLastItem);
      setCurrentItem(currentItem[0]);

      setShowImage(true);
      setLoading(false);
    }).catch(err => console.log(err))
  };

  const saveMapping = () => {
    setLoading(true);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_DOCAI_BACKEND_URL}/document/map`,
      data: {
        document: mappedDocument
      },
    }).then(() => {
      setLoading(false);
      setOpenMessage(true);
    }, (error) => {
      if (error?.response?.data?.Message) {
        console.log(error.response.data.Message);
        setMappingErrorMessage(error.response.data.Message);
      } else {
        setMappingErrorMessage('Error saving the Mapping applied.');
      }
      setMappingError(true);
      setLoading(false);
    });
  };

  const createEntrySummary = () => {
    setLoading(true);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_DOCAI_BACKEND_URL}/entry-summary`,
      data: mappedDocument,
    }).then((res) => {
      setOpenEntryMessage(true);
      parent.postMessage("Id:{" + res.data.response[0].TransactionID + "}", "*");
      setLoading(false);
    }, (error) => {
      if (error?.response?.data?.Message) {
        console.log(error.response.data.Message);
        setEntrySummaryErrorMessage(error.response.data.Message);
      } else {
        setEntrySummaryErrorMessage('Error creating the Entry Summary.');
      }
      setEntrySummaryError(true);
      setLoading(false);
    });
  };

  const handleFileChange = (files) => {
    if (files.length > 0) {
      setUrl(URL.createObjectURL(files[0]));
      setUploadedFile(files[0]);
    }
  };

  const parseTableIndexToRowsIndex = (columnIndex, tableIndex, pageIndex) => {
    let rowsIndex = 0;
    for (let i = 0; i <= pageIndex; i++) {
      rowsIndex += pagesArray[i].keyPairs.length;
      if (pageIndex === i) {
        for (let j = 0; j <= tableIndex; j++) {
          if (tableIndex === j) {
            rowsIndex += columnIndex;
            return rowsIndex;
          } else {
            rowsIndex += pagesArray[i].tables[j].header.length;
          }
        }
      } else {
        for (let j = 0; j < pagesArray[i].tables.length; j++) {
          rowsIndex += pagesArray[i].tables[j].header.length;
        }
      }
    }
  };

  const onAcelynkKeyChanged = (option, value, key, header, tableIndex, pageIndex = currentPage - 1) => {
    let index = -1;
    if (header) {
      index = parseTableIndexToRowsIndex(value, tableIndex, pageIndex);
      const newHeader = pagesArray[pageIndex].tables[tableIndex].header.map((x, hIdx) => {
        if (x.tableHeader.trim().replace(/\n/g, '') === key &&
            hIdx === value) {
          return { ...x, acelynkField: option };
        }
        return x;
      });
      pagesArray[pageIndex].tables[tableIndex].header = newHeader;
    } else {
      index = mappedDocument.rows.findIndex(row =>
        row.fieldLabel === key &&
        row.fieldValue === value &&
        row.pageIndex == pageIndex &&
        row.tableIndex === null);
      const newKeyPairs = pagesArray[pageIndex].keyPairs.map(x => {
        if (x.fieldName.trim().replace(/\n/g, '') === key &&
            x.fieldValue === value) {
          return { ...x, acelynkField: option };
        }
        return x;
      });
      pagesArray[pageIndex].keyPairs = newKeyPairs;
    }
    // Set new value from the mapped values returned by parser and the pages array
    mappedDocument.rows[index].acelynkField = option;
    mappedDocument.pages = pagesArray;
    setMappedDocument(JSON.parse(JSON.stringify(mappedDocument)));
  };

  const onSelectedValueChanged = (option, value, key, pageIndex = currentPage - 1) => {
    // Find the index with the same key and page. We must assure the tableIndex is null,
    // otherwise we could update the useKeyAsValue from a table's header and we only want
    // to update the useKeyAsValue from a keyValues' header section.
    const index = mappedDocument.rows.findIndex(row =>
      row.fieldLabel === key &&
      row.fieldValue === value &&
      row.pageIndex == pageIndex &&
      row.tableIndex === null);
    const newKeyPairs = pagesArray[pageIndex].keyPairs.map(x => {
      if (x.fieldName.trim().replace(/\n/g, '') === key &&
          x.fieldValue === value) {
        return { ...x, useKeyAsValue: option };
      }
      return x;
    });
    pagesArray[pageIndex].keyPairs = newKeyPairs;
    // Set new value from the mapped values returned by parser and the pages array
    mappedDocument.rows[index].useKeyAsValue = option;
    mappedDocument.pages = pagesArray;
    setMappedDocument(JSON.parse(JSON.stringify(mappedDocument)));
  };

  const applyTableMapping = (currentPage, currentTable, pageIndex, tableIndex) => {
    const currentTableColumnSize = pagesArray[currentPage - 1].tables[currentTable - 1].header.length;
    const selectedTableColumnSize = pagesArray[pageIndex].tables[tableIndex].header.length;

    if (currentTableColumnSize !== selectedTableColumnSize) {
      // If current table and selected table have different amount of columns, the mapping is skipped
      return;
    }

    pagesArray[pageIndex].tables[tableIndex].header.forEach((_, idx) => {
      // For each column header copy the acelynk field mapped
      pagesArray[pageIndex].tables[tableIndex].header[idx].acelynkField = pagesArray[currentPage - 1].tables[currentTable - 1].header[idx].acelynkField;
      const index = mappedDocument.rows.findIndex(row => row === mappedDocument.rows.filter(row =>
        row.pageIndex === pageIndex &&
        row.tableIndex === tableIndex)[idx]);
      // Then copy the value to the mappedDocument rows array
      mappedDocument.rows[index].acelynkField = pagesArray[pageIndex].tables[tableIndex].header[idx].acelynkField;
    });
    // Finally, assign the skip field to the table if all the header columns are skipped
    pagesArray[pageIndex].tables[tableIndex].skip = pagesArray[pageIndex].tables[tableIndex].header.findIndex(column => column.acelynkField !== 'Skip') < 0;
  }

  const onMap = (currentPage, currentTable, pageSelected, tableSelected) => {
    if (isNaN(parseInt(pageSelected))) {
      setMappingError(true);
      setMappingErrorMessage('Please, select a page first.');
      return;
    }

    if (isNaN(parseInt(tableSelected))) {
      setMappingError(true);
      setMappingErrorMessage('Please, select a table first.');
      return;
    }

    if (currentPage === pageSelected && currentTable === tableSelected) {
      setMappingError(true);
      setMappingErrorMessage('Mapping should be applied to a different page and table.');
      return;
    }

    if (pageSelected === 0 && tableSelected === 0) {
      // Apply mapping to all pages and tables
      let pageIndex = 0;
      for (pageIndex = 0; pageIndex < pagesArray.length; pageIndex++) {
        let tableIndex = 0;
        for (tableIndex = 0; tableIndex < pagesArray[pageIndex].tables.length; tableIndex++) {
          if (pageIndex === (currentPage - 1) && tableIndex === (currentTable - 1)) {
            continue;
          }
          applyTableMapping(currentPage, currentTable, pageIndex, tableIndex);
        }
      }
    } else if (pageSelected === 0 && tableSelected > 0) {
      // Apply mapping to all pages and selected table
      let pageIndex = 0;
      const tableIndex = tableSelected - 1;
      for (pageIndex = 0; pageIndex < pagesArray.length; pageIndex++) {
        if (pageIndex === (currentPage - 1) && tableSelected === currentTable) {
          continue;
        }
        applyTableMapping(currentPage, currentTable, pageIndex, tableIndex);
      }
    } else if (pageSelected > 0 && tableSelected === 0) {
      // Apply mapping to selected page and all tables
      const pageIndex = pageSelected - 1;
      let tableIndex = 0;
      for (tableIndex = 0; tableIndex < pagesArray[pageIndex].tables.length; tableIndex++) {
        if (pageSelected === currentPage && tableIndex === (currentTable - 1)) {
          continue;
        }
        applyTableMapping(currentPage, currentTable, pageIndex, tableIndex);
      }
    } else {
      // Apply mapping to selected page and table
      const pageIndex = pageSelected - 1;
      const tableIndex = tableSelected - 1;
      const currentTableColumnSize = pagesArray[currentPage - 1].tables[currentTable - 1].header.length;
      const selectedTableColumnSize = pagesArray[pageIndex].tables[tableIndex].header.length;

      if (currentTableColumnSize !== selectedTableColumnSize) {
        setMappingError(true);
        setMappingErrorMessage('Mapping should be applied to a table with the same number of columns.');
        return;
      }

      applyTableMapping(currentPage, currentTable, pageIndex, tableIndex);
    }

    mappedDocument.pages = pagesArray;
    setMappedDocument(JSON.parse(JSON.stringify(mappedDocument)));
    setMappingApplied(true);
  }

  const classes = useStyles();
  const classes2 = dropStyles();

  return (
    <div>
      <Snackbar open={openMessage} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Mapping saved successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={openEntryMessage} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info">
          Entry summary created!
        </Alert>
      </Snackbar>
      <Snackbar open={entrySummaryError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Entry summary request failed! {entrySummaryErrorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={mappingApplied} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info">
          Mapping applied with success!
        </Alert>
      </Snackbar>
      <Snackbar open={mappingError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {mappingErrorMessage}
        </Alert>
      </Snackbar>
      <Backdrop className={classes2.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!showImage && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <h2 className={classes.cardTitleWhite}>Upload Documents</h2>
              </CardHeader>
              <CardBody>
                <DropzoneArea filesLimit={1} onChange={(files) => handleFileChange(files)} />
              </CardBody>
              <CardFooter>
                <Button onClick={() => handleChange()} color="success">
                  Parse Document
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      )}
      {showImage && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="warning">
                <h2 className={classes.cardTitleWhite}>Map Document</h2>
              </CardHeader>
              <CardBody>
                <Pagination pageSize={1} pageCount={pagesArray.length} onPageChange={(page)=>setPage(page)} currentPage={currentPage} />
                <MappedPage pageItem={currentItem}
                            pageCount={pagesArray.length}
                            currentPage={currentPage}
                            mappedDocument={mappedDocument}
                            onAcelynkKeyChanged={(option, value, key, header, tableIndex) => onAcelynkKeyChanged(option, value, key, header, tableIndex)}
                            onSelectedValueChanged={(option, value, key) => onSelectedValueChanged(option, value, key)}
                            onMap={(currentPage, currentTable, pageSelected, tableSelected) => onMap(currentPage, currentTable, pageSelected, tableSelected)}/>
              </CardBody>
              <CardFooter>
                <Button onClick={saveMapping} color="warning"> Save Mapping </Button>
                <Button onClick={createEntrySummary} color="info"> Craete Entry Summary </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <DocumentViewer style={{ display: showImage ? "block" : "none" }} url={url} currentPage={currentPage}/>
        </GridContainer>
      )
      }
    </div >
  );
}
