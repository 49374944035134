import React from 'react';

// #region external libraries
import PDFViewer from 'mgr-pdf-viewer-react';

// #endregion

// #region internal components
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// #endregion

// #region constants

// #endregion

// #region component

/**
 * This component is used to preview the required Document while the user maps the key value pairs
 */
const DocumentViewer = ({ url, currentPage }) => {
    return <GridItem
        xs={12}
        sm={12}
        md={4}>
        <Card>
            <CardBody>
                <div>
                    <PDFViewer style={{ height: "auto", width: "100%" }} document={{
                        url: url
                    }}
                    page={currentPage}
                    hideNavbar={true}
                    />
                </div>
            </CardBody>
        </Card>
    </GridItem>;
}
// #endregion

export default DocumentViewer;